import React, { ReactElement } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Bio from '../components/bio'
import { rhythm } from '../utils/typography'

interface Props {
	data: {
		markdownRemark: {
			html: string
			excerpt: string
			frontmatter: {
				title: string
				description: string
				date: string
			}
		}
		site: {
			siteMetadata: {
				title: string
			}
		}
	}
	pageContext: unknown
}

const BlogPostTemplate = ({ data /*, pageContext*/ }: Props): ReactElement => {
	const post = data.markdownRemark
	// const { previous, next } = pageContext

	// TODO: Fix main/article footer/footer padding/margin space...

	return (
		<Layout>
			<SEO
				title={post.frontmatter.title}
				description={post.frontmatter.description || post.excerpt}
			/>
			<article>
				<header>
					<h1
						style={{
							marginTop: rhythm(1),
							marginBottom: 0,
						}}
					>
						{post.frontmatter.title}
					</h1>
					<time dateTime={post.frontmatter.date}>{post.frontmatter.date}</time>
				</header>
				<section dangerouslySetInnerHTML={{ __html: post.html }} />
				<hr
					style={{
						marginBottom: rhythm(1),
					}}
				/>
				<footer>
					<Bio />
				</footer>
			</article>

			{/* <nav>
				<ul
					style={{
						display: `flex`,
						flexWrap: `wrap`,
						justifyContent: `space-between`,
						listStyle: `none`,
						padding: 0,
					}}
				>
					<li>
						{previous && (
							<Link to={previous.fields.slug} rel="prev">
								← {previous.frontmatter.title}
							</Link>
						)}
					</li>
					<li>
						{next && (
							<Link to={next.fields.slug} rel="next">
								{next.frontmatter.title} →
							</Link>
						)}
					</li>
				</ul>
			</nav> */}
		</Layout>
	)
}

export default BlogPostTemplate

export const pageQuery = graphql`
	query BlogPostBySlug($slug: String!) {
		site {
			siteMetadata {
				title
			}
		}
		markdownRemark(fields: { slug: { eq: $slug } }) {
			id
			excerpt(pruneLength: 160)
			html
			frontmatter {
				title
				date(formatString: "YYYY-MM-DD")
				description
			}
		}
	}
`
