/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { ReactElement } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import { rhythm } from '../utils/typography'

const Bio = (): ReactElement => {
	const data = useStaticQuery(graphql`
		query BioQuery {
			avatar: file(absolutePath: { regex: "/me.png/" }) {
				childImageSharp {
					gatsbyImageData(width: 50, height: 50, layout: FIXED)
				}
			}
			site {
				siteMetadata {
					author
					socialNetworks {
						twitter
					}
				}
			}
		}
	`)

	const { author, socialNetworks: networks } = data.site.siteMetadata
	return (
		<div style={{ display: `flex` }}>
			<GatsbyImage
				image={data.avatar.childImageSharp.gatsbyImageData}
				alt={author}
				style={{
					marginRight: rhythm(1 / 2),
					marginBottom: 0,
					minWidth: 50,
					borderRadius: `100%`,
				}}
				imgStyle={{
					borderRadius: `50%`,
				}}
			/>
			<p>
				Written by <strong>{author}</strong> who lives and works remotely from
				Lisbon building awesome things to the world.
				<br />
				<a href={networks.twitter}>You should follow me on Twitter</a>
			</p>
		</div>
	)
}

export default Bio
